export const PATH_PAGE = {
  client: {
    root: getClientPath(),
    detail: getClientPath("/detail"),
  },
  estimation: {
    root: getEstimationPath(),
    detail: getEstimationPath("/detail"),
    pricing: getEstimationPath("/pricing"),
    pricingDetail: getEstimationPath("/pricing/pricing-detail"),
  },
  contracts: {
    root: getContractPath(),
    detail: getContractPath("/detail"),
    milestones: getContractPath("/milestone"),
    milestoneDetail: getContractPath("/milestone/milestone-detail"),
    purchaseOrder: getContractPath("/purchase-order"),
    purchaseOrderDetail: getContractPath(
      "/purchase-order/purchase-order-detail"
    ),
  },
  dashboard: "/dashboard",
  login: "/login",
  page404: "/404",
  pageRoles: "/roles",
  pageSettings: "/settings",
  pageResources: "/resources",
  pageResourcesDetails: "/resourcesdetails",
  pageAllocations:getClientPath("/allocations"),
  pageTimesheets:getClientPath("/timesheets"), 
  pageInvoicing:getClientPath("/invoicing"), 
  permissionDenied: "/permissionDenied"
};

function getClientPath(path) {
  return "/client" + (path ?? "");
}

function getEstimationPath(path) {
  return getClientPath() + "/estimation" + (path ?? "");
}

function getContractPath(path) {
  return getClientPath() + "/contracts" + (path ?? "");
}
