// component
import Iconify from "../../components/Iconify";
import { PATH_PAGE } from "../../routes/paths";
import MenuClient from "../../assets/svg/menu/MenuClient";
import MenuResource from "../../assets/svg/menu/MenuResource";
import MenuRole from "../../assets/svg/menu/MenuRole";
import MenuSetting from "../../assets/svg/menu/MenuSetting";
import MenuClientDetail from "../../assets/svg/menu/MenuClientDetail";
import MenuEstimation from "../../assets/svg/menu/MenuEstimation";
import MenuTimeSheet from "../../assets/svg/menu/MenuTimeSheet";
import MileStone from "../../assets/svg/menu/Milestone";
import EffortEstimation from "../../assets/svg/menu/EffortEstimations";
import Pricing from "../../assets/svg/menu/pricing";
import Contracts from "../../assets/svg/menu/contracts";
import PurchaseOrder from "../../assets/svg/menu/purchase order";
// ----------------------------------------------------------------------
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
export const DashboardNavConfig = (translate) => [
  {
    title: translate("clientManagement"),
    path: PATH_PAGE.dashboard,
    icon: <MenuClient />,
  },
  {
    title: translate("ResourceManagement"),
    path: PATH_PAGE.pageResources,
    icon: <MenuResource />,
    children: [
      {
        title: translate("Resources"),
        path: PATH_PAGE.pageResources,
      },
      {
        title: translate("ResourcesDetail"),
        path: PATH_PAGE.pageResourcesDetails,
      },
    ],
  },
  {
    title: translate("roles"),
    path: PATH_PAGE.pageRoles,
    icon: <MenuRole />,
  },
  {
    title: translate("settings"),
    path: PATH_PAGE.pageSettings,
    icon: <MenuSetting />,
  },
];

export const SelectedClientNavConfig = (clientName, translate) => [
  {
    title: clientName,
    path: PATH_PAGE.client.detail,
    icon: <MenuClientDetail />,
  },
  {
    title: translate("estimation"),
    path: PATH_PAGE.estimation.root,
    icon: <MenuEstimation />,
    children: [
      {
        title: translate("EffortEstimation"),
        path: PATH_PAGE.estimation.root,
        subicon: <EffortEstimation />,
      },
      {
        title: translate("Pricing"),
        path: PATH_PAGE.estimation.pricing,
        subicon: <Pricing />,
      },
    ],
  },
  {
    title: translate("SOWContract"),
    path: PATH_PAGE.contracts.root,
    icon: getIcon("mdi:credit-card-outline"),
    children: [
      {
        title: translate("Contracts"),
        path: PATH_PAGE.contracts.root,
        subicon: <Contracts />,
      },
      {
        title: translate("Milestones"),
        path: PATH_PAGE.contracts.milestones,
        subicon: <MileStone />,
      },
      {
        title: translate("PurchaseOrders"),
        path: PATH_PAGE.contracts.purchaseOrder,
        subicon: <PurchaseOrder />,
      },
    ],
  },
  // {
  //   title: translate("Allocations"),
  //   path: PATH_PAGE.pageAllocations,
  //   icon: <MenuAllocation />,
  // },
  // {
  //   title: translate("Timesheets"),
  //   path: PATH_PAGE.pageTimesheets,
  //   icon: <MenuTimeSheet />,
  // },
  // {
  //   title: translate("invoicing"),
  //   path: PATH_PAGE.pageInvoicing,
  //   icon: <MenuInvoicing />,
  // },
];

export const DashboardNavConfigPermissionDenied = (translate) => [
  {
    title: translate("clientManagement"),
    path: PATH_PAGE.dashboard,
    icon: <MenuClient />,
  },
  {
    title: translate("ResourceManagement"),
    path: PATH_PAGE.dashboard,
    icon: <MenuResource />,
  },
  {
    title: translate("Timesheets"),
    path: PATH_PAGE.pageTimesheets,
    icon: <MenuTimeSheet />,
  },
  {
    title: translate("roles"),
    path: PATH_PAGE.dashboard,
    icon: <MenuRole />,
  },
  {
    title: translate("settings"),
    path: PATH_PAGE.dashboard,
    icon: <MenuSetting />,
  },
];